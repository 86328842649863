import React from 'react'
import './Indicator.scss'

export function Indicator(props: any) {

  let className = 'indicator'
  let label
  let actions

  if (props.persistance === 'temp') {
    className += ' bg-warning';
    label = 'Temp';
    actions = [<i className='fas fa-undo fs-3' />, <i className='fas fa-download fs-3' />]
  } else if (props.persistance === 'profile') {
    className += ' bg-success';
    label = 'Prof';
    actions = <i className='fas fa-times fs-3' />
  } else {
    className += ' bg-primary';
    label = 'Base';
  }

  return (
    <div className="stack-status">
      {actions}
      <div className={className}>
        {label}
      </div>
    </div>
  )
}
