/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'

const InfoPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row mb-8'>

    </div>
    {/* end::Row */}

  </>
)

const InfoWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Info</PageTitle>
      <InfoPage />
    </>
  )
}

export {InfoWrapper}
