
export function Status() {
  return (
    <>
      <div className='row'>
        <div className='col'>
          <strong>
            Input:
          </strong>
          Oppo UHD
        </div>
        <div className='col'>
          <strong>
            Output:
          </strong>
          JVC NZ9
        </div>
      </div>
    </>
  )
}
