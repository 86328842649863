import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {HDRSettings} from './components/HDRSettings'
import {DeintMotion} from './components/DeintMotion'
import {ArtifactRemoval} from './components/ArtifactRemoval'
import {ScalePost} from './components/ScalePost'
import {SourceOverrides} from './components/SourceOverrides'
import {SourceColor} from './components/SourceColor'

const settingsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Settings',
    path: '/crafted/pages/settings/hrd-settings',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SettingsPage = () => (
  <Routes>
    <Route
      element={
        <>
          <Outlet />
        </>
      }
    >
      <Route
        path='hdr-settings'
        element={
          <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>HDR Settings</PageTitle>
            <HDRSettings />
          </>
        }
      />
      <Route
        path='deint-motion'
        element={
          <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>Deinterlacin &amp; Motion</PageTitle>
            <DeintMotion />
          </>
        }
      />
      <Route
        path='artifact-removal'
        element={
          <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>Artifact Removal</PageTitle>
            <ArtifactRemoval />
          </>
        }
      />
      <Route
        path='scale-post'
        element={
          <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>Upscaling &amp; Sharpening</PageTitle>
            <ScalePost />
          </>
        }
      />
      <Route
        path='source-overrides'
        element={
          <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>Incoming Video Overrides</PageTitle>
            <SourceOverrides />
          </>
        }
      />
      <Route
        path='source-color'
        element={
          <>
            <PageTitle breadcrumbs={settingsBreadCrumbs}>Image Adjustments</PageTitle>
            <SourceColor />
          </>
        }
      />
    </Route>
    <Route index element={<Navigate to='/crafted/pages/Settings/display' />} />
  </Routes>
)

export default SettingsPage
