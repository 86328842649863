/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row mb-8'>
      <div className='col-xl-2'>
      Resolution: 
      </div>
      <div className='col-xl-4'>
        <select className="form-select" aria-label="Default select example">
          <option selected>4096 x 2160</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select> 
      </div>
    </div>
    <div className='row mb-8'>
      <div className='col-xl-2'>
      Bit Depth: 
      </div>
      <div className='col-xl-1'>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
          <label className="form-check-label" htmlFor="inlineRadio1">12 bit</label>
        </div>
      </div>
      <div className='col-xl-1'>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
          <label className="form-check-label" htmlFor="inlineRadio2">10 bit</label>
        </div>
      </div>
      <div className='col-xl-1'>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
          <label className="form-check-label" htmlFor="inlineRadio3">8 bit</label>
        </div>
      </div>
    </div>
    {/* end::Row */}
    
    {/* begin::Row */}
    <div className='row mb-8'>
      <div className='col-xl-2'>
      Color Space: 
      </div>
      <div className='col-xl-1'>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
          <label className="form-check-label" htmlFor="inlineRadio1">RGB</label>
        </div>
      </div>
      <div className='col-xl-1'>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
          <label className="form-check-label" htmlFor="inlineRadio2">RED709</label>
        </div>
      </div>
      <div className='col-xl-1'>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
          <label className="form-check-label" htmlFor="inlineRadio3">bt2020</label>
        </div>
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row mb-8'>
      <div className='col-xl-2'>
      Levels: 
      </div>
      <div className='col-xl-4'>
        <select className="form-select" aria-label="Default select example">
          <option selected>Limited Range</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select> 
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row mb-8'>
      <div className='col-xl-2'>
        &nbsp;
      </div>
      <div className='col-xl-1'>
        Black Levels:
      </div>
      <div className='col-xl-3'>
        <div className="input-group">
          <div className="input-group-btn">
            <div className="input-group-text">-</div> 
          </div>
          <input type="text" id="quantity" name="quantity" className="form-control input-number" value="10" min="1" max="100" />
          <div className="input-group-btn">
            <div className="input-group-text">+</div> 
          </div>
        </div>
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row mb-8'>
      <div className='col-xl-2'>
        &nbsp;
      </div>
      <div className='col-xl-1'>
        White Levels:
      </div>
      <div className='col-xl-3'>
        <div className="input-group">
          <div className="input-group-btn">
            <div className="input-group-text">-</div> 
          </div>
          <input type="text" id="quantity" name="quantity" className="form-control input-number" value="10" min="1" max="100" />
          <div className="input-group-btn">
            <div className="input-group-text">+</div> 
          </div>
        </div>
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row mb-8'>
      <div className='col-xl-2'>
      Peak Luminance (Nits): 
      </div>
      <div className="col-lg-2">
        <div className="input-group">
          <div className="input-group-btn">
            <div className="input-group-text">-</div> 
          </div>
          <input type="text" id="quantity" name="quantity" className="form-control input-number" value="10" min="1" max="100" />
          <div className="input-group-btn">
            <div className="input-group-text">+</div> 
          </div>
        </div>
      </div>
    </div>
    {/* end::Row */}

  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
