import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Drawer toggle */}
        <div
          className={clsx('btn btn-icon btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
          id='kt_activities_toggle'
        >
          
          <KTSVG
            path='/media/icons/custom/power.svg'
            className={toolbarButtonIconSizeClass}
          />  
        </div>
        {/* end::Drawer toggle */}
      </div>


    </div>
  )
}

export {Topbar}
