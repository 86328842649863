import React from 'react'
import {Indicator} from '../../profile/components/indicator/Indicator'

export function Dropdown(props: any) {

  let options: any = []
  let stackStatus = props.stackStatus

  props.optionList.forEach(function (option: string) {
    options.push(<option selected>{ option }</option>)
  })
  
  return (
    <div className='row mb-8 flex-nowrap'>
      <div className='col-sm-2'>
        { props.name } 
      </div>
      <div className='col-sm-7'>
        <select className="form-select" aria-label="Default select example">
          { options }
        </select> 
      </div>
      <div className='col-sm-3'>
        <Indicator persistance={stackStatus}/>
      </div>
    </div>
  )
}
