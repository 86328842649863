import React from 'react'
import {Dropdown} from '../../control/dropdown/Dropdown'
import config from '../../../madvr.json';

export function DeintMotion() {

  let controls: any[] = []
  let settings: any = config.Setup.deintMotion.Properties

  for (var key in settings) {
    let options: string[] = Object.values(settings[key].Values)
    controls.push(<Dropdown name={settings[key].Name} stackStatus="base" optionList={ options }/>)
  }
  return (
    <>
      { controls }
    </>

  )
}
