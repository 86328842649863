/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {

  return (
    <>
      <AsideMenuItem
        to='/info'
        title='Info'
      />
      <AsideMenuItem
        to='/builder'
        title='Source Devices'
      />
      <AsideMenuItem
        to='/builder'
        title='Displays'
      />
      <AsideMenuItem
        to='/builder'
        title='Profiles'
      />
      <AsideMenuItemWithSub
        to='/crafted/pages/config'
        title='Configurations'
      >
        <AsideMenuItem to='/crafted/pages/config/display' title='Display' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/config/screen' title='Screen' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/config/black-bar' title='Black Bar' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/config/hdmi' title='HDMI' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/config/menu' title='Menu' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/crafted/pages/settings'
        title='Settings'
      >
        <AsideMenuItem to='/crafted/pages/settings/hdr-settings' title='HDR Settings' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/settings/deint-motion' title='Deinterlacing &amp; Motion' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/settings/artifact-removal' title='Artifact Removal' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/settings/scale-post' title='Upscaling &amp; Sharpening' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/settings/source-overrides' title='Incoming Video Overrides' hasBullet={true} />
        <AsideMenuItem to='/crafted/pages/settings/source-color' title='Image Adjustments' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/error'
        title='Fimware Update'
      />

      <AsideMenuItem
        to='/error'
        title='Settings Management'
      />

      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Utilities'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Test Patterns' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='API Console' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Advanced Stats' hasBullet={true} />
      </AsideMenuItemWithSub>

    </>
  )
}
